import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import moment from "moment";

export function RecentArticlesComponent(props: { currentArticleId?: number }) {
  const { currentArticleId } = props;
  const recentArticles = useSelector(
    (state: RootState) => state.articles.articles
  );

  return (
    <>
      <br />
      <br />
      <br />
      <h3>Recent Articles</h3>
      {recentArticles.length < 1 ? (
        <Spinner animation="border" />
      ) : (
        <>
          <ul>
            {recentArticles.slice(0, 5).map((article) => {
              return (
                <li key={article.id}>
                  <Link
                    to={`/article/${article.id}`}
                    onClick={(e) => {
                      if (article.id === currentArticleId) {
                        window.scrollTo(0, 0);
                      }
                    }}
                  >
                    {article.title}
                  </Link>{" "}
                  - {moment(article.created).format("D MMM YYYY")}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
}
