import { Link } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Helmet } from "react-helmet";

function App(props: any) {
  return (
    <>
      <Helmet>
        <title>DongGyun's blog</title>
        <meta name="description" content="DongGyun's blog" />
        <meta name="description" content="DongGyun's blog" />
        <meta property="og:title" content="DongGyun's blog" />
        <meta property="og:description" content="DongGyun's blog" />
      </Helmet>
      <Navbar bg="light" expand="lg" style={{ marginBottom: "5rem" }}>
        <Container>
          <Navbar.Brand as={Link} to="/">
            DongGyun's blog
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="https://donggyun.com">About Me</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container style={{ minHeight: "1000px" }}>{props.children}</Container>

      <footer style={{ marginTop: "3rem", marginBottom: "3rem" }}>
        <Container>
          <hr />
          <p className="text-center">&copy; 2021 DongGyun Han </p>
        </Container>
      </footer>
    </>
  );
}

export default App;
