import React from "react";
import App from "./App";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { RootState } from "./store";
import { useSelector } from "react-redux";

export function ArticleListView() {
  const articles = useSelector((state: RootState) => state.articles.articles);

  return (
    <App>
      <h2>Articles</h2>
      {articles.length < 1 ? (
        <Spinner animation="border" />
      ) : (
        <ul>
          {articles.map((article) => {
            return (
              <li key={article.id}>
                <Link to={`/article/${article.id}`}>{article.title}</Link> -{" "}
                {moment(article.created).format("D MMM YYYY")}
              </li>
            );
          })}
        </ul>
      )}
    </App>
  );
}
