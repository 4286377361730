import App from "./App";
import { RecentArticlesComponent } from "./RecentArticles";

export function ErrorView({ error }: { error: string }) {
  return (
    <App>
      <h4>Something went wrong... :(</h4>
      <RecentArticlesComponent />
    </App>
  );
}
