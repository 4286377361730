import React from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import App from "./App";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import Moment from "react-moment";
import { Helmet } from "react-helmet";
import { RootState } from "./store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateArticle } from "./article-slice";
import { RecentArticlesComponent } from "./RecentArticles";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";

export function ArticleView() {
  let { id } = useParams<{ id: string }>();

  const article = useSelector((state: RootState) =>
    state.articles.articles.find((a) => a.id === parseInt(id))
  );

  const [error, setError] = React.useState<boolean>();
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!article?.content) {
      axios
        .get(
          "https://29hh9vjjqc.execute-api.us-east-1.amazonaws.com/prod/getArticle",
          {
            params: { id: id },
            headers: {
              "Content-Type": "application/json",
            },
            responseType: "json",
          }
        )
        .then((response) => {
          dispatch(updateArticle(response.data));
        })
        .catch((error) => {
          console.log(error);
          setError(true);
        });
    }
  }, [id, article, dispatch]);

  if (error) {
    return <Redirect to="/error" />;
  }

  return (
    <App>
      {!article ? (
        <Spinner animation="border" />
      ) : (
        <>
          <h1>{article.title}</h1>
          <Moment format="D MMM YYYY">{article.created}</Moment>
          <br />
          <br />
          {!article.content ? (
            <Spinner animation="border" />
          ) : (
            <>
              <Helmet>
                <title>{article.title}</title>
                <meta property="og:title" content={article.title} />
                <meta
                  name="description"
                  content={article.content!.slice(0, 250).concat("...")}
                />
                <meta
                  property="og:description"
                  content={article.content!.slice(0, 250).concat("...")}
                />
              </Helmet>
              <ReactMarkdown
                children={article.content}
                remarkPlugins={[remarkGfm]}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                      <SyntaxHighlighter
                        children={String(children).replace(/\n$/, "")}
                        language={match[1]}
                        PreTag="div"
                        style={okaidia}
                        // {...props}
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                }}
              />
            </>
          )}
        </>
      )}
      <RecentArticlesComponent currentArticleId={parseInt(id)} />
    </App>
  );
}
