import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  useLocation,
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import "./index.css";
import { ArticleView } from "./Article";
import { ArticleListView } from "./List";
import { ErrorView } from "./Error";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-dark-4/dist/bootstrap-dark.css";
import { store } from "./store";
import { Provider } from "react-redux";
import axios from "axios";
import { setArticles } from "./article-slice";
import { useDispatch } from "react-redux";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function LoadArticleList() {
  const dispatch = useDispatch();
  useEffect(() => {
    axios
      .get(
        "https://29hh9vjjqc.execute-api.us-east-1.amazonaws.com/prod/listArticles",
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "json",
        }
      )
      .then((response) => {
        dispatch(setArticles(response.data));
      });
  }, [dispatch]);
  return null;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <LoadArticleList />
        <ScrollToTop />
        <Switch>
          <Route path="/article/:id" component={ArticleView} />
          <Route path="/list" component={ArticleListView} />
          <Route path="/error" component={ErrorView} />
          <Route path="/" component={ArticleListView} />
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
