import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Article } from "./Types";

interface ArticleState {
  articles: Article[];
}

const initialState: ArticleState = {
  articles: [],
};

const articleSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setArticles: (state, action: PayloadAction<Article[]>) => {
      state.articles = action.payload;
    },
    updateArticle: (state, action: PayloadAction<Article>) => {
      state.articles.find(
        (article) => article.id === action.payload.id
      )!.content = action.payload.content;
    },
  },
});

export const { setArticles, updateArticle } = articleSlice.actions;
export const getArticles = (state: ArticleState) => state.articles;
export default articleSlice.reducer;
